import React, { Component } from 'react'
import axios from "axios";
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage }  from "formik";


//define Customer API URL call
// let baseurl = 'http://scsitev1apis-env.eba-hnxtukew.us-east-2.elasticbeanstalk.com'
// let baseurl = 'https://validation.stickchart.dev'
// let baseurl = 'http://192.168.1.159:8081'
let baseurl = process.env.GATSBY_API_TARGET
let regurl = baseurl + '/api/registeredusers/'
let refurl = baseurl + '/api/referredusers/'
let access_token = process.env.GATSBY_API_KEY

if (process.env.GATSBY_ACTIVE_ENV === 'development') {
  console.log("***LOG RESPONSE*** GATSBY_API_KEY: " + process.env.GATSBY_API_KEY)
  console.log("***LOG RESPONSE*** API_KEY: " + process.env.API_KEY)
  console.log("***LOG RESPONSE*** NODE_ENV: " + process.env.NODE_ENV)
  console.log("***LOG RESPONSE*** ACTIVE_ENV: " + process.env.GATSBY_ACTIVE_ENV)
}

//Set Initial Values for Form
const initialValues = {
    firstName: "",
    lastName: "",
    eMailPrimary: "",
    usertype: "",
};

const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(45, 'Too Long!')
        .required('First Name Is Required'),
    lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(45, 'Too Long!')
        .required('Last Name Is Required'),
    eMailPrimary: Yup.string().email('Invalid email').required('Your Email Address Is Required'),
    usertype: Yup.string().required("Please select your role!"),
});


export default class AboutRegister extends Component {
    state = {
        items: [],
        value: "",
        error: null
    };

     localID = "stickchart:_sc_registered";


    componentDidMount () {

        if (typeof window !== 'undefined') {
            let regCheck = localStorage.getItem(this.localID);
            if (regCheck === 'true') {
                var bReg = document.getElementById("regform");
                var bRegHead = document.getElementById("registerheading");
                var bRegThanks = document.getElementById("alreadyregistered");
                //Hides the registration form
                bReg.style.display = "none";
                //Hides the registration header
                bRegHead.style.display = "none";
                //Shows the thanks for already registering
                bRegThanks.style.display = "block";
            }
        }

    }

    submitCloseForm = e => {
        var bReg = document.getElementById("regform");
        var bRegHead = document.getElementById("registerheading");
        var bRegThanks = document.getElementById("completeform");
        //Hides the registration form
        bReg.style.display = "none";
        //Hides the registration header
        bRegHead.style.display = "none";
        //Shows the thanks for already registering
        bRegThanks.style.display = "block";
    };

    render() {
        

        return (

            <div className="about-reg-form pt-8 scmd:py-10">
                <Formik
                    class="mb-4 md:flex md:flex-wrap md:justify-between"
                    initialValues={initialValues}
                    validationSchema={SignupSchema}
                    onSubmit={(fields, { setSubmitting }) => {
                        setTimeout(() => {
                            //Get the SessionID - used to commit data to API
                            const sessionID = typeof window !== 'undefined' && window.localStorage.getItem('stickchart:_sc_id');;
                            
                            //Writing values to localStorage
                            //Registration complete, will prevent pop-up in the future
                            typeof window !== 'undefined' && window.localStorage.setItem('stickchart:_sc_registered', true);
                            //Sets Usertype for the Registered User
                            typeof window !== 'undefined' && window.localStorage.setItem('stickchart:_sc_registered_usertype', fields.usertype);
                            //Sets First Name for the Registered User
                            typeof window !== 'undefined' && window.localStorage.setItem('stickchart:_sc_registered_fName', fields.firstName);
                            //Sets Last Name for the Registered User
                            typeof window !== 'undefined' && window.localStorage.setItem('stickchart:_sc_registered_lName', fields.lastName);
                            //Sets Email for the Registered User
                            typeof window !== 'undefined' && window.localStorage.setItem('stickchart:_sc_registered_email', fields.eMailPrimary);


                            // //API call to commit data to backend
                            axios.post(regurl, {
                                session_id: sessionID,
                                email_address: fields.eMailPrimary,
                                first_name: fields.firstName,
                                last_name: fields.lastName,
                                user_type: fields.usertype,
                            }, {
                                headers: {
                                    // Pass api key in via auth header
                                    'Authorization': 'Api-Key ' + access_token
                                }
                            })
                            .then((response) => {
                                // console.log("**Registration - Add RegisteredUser via API success** " + response);
                                //We know there was a successful user registration, so now add referred users
                                if (process.env.GATSBY_ACTIVE_ENV === 'development') {
                                    console.log("***LOG RESPONSE*** **Axios Response** " + JSON.stringify(response));
                                }
                            }, (error) => {
                                // console.log("**Registration - Add RegisteredUser via API ERROR** " + error)
                                if (process.env.GATSBY_ACTIVE_ENV === 'development') {
                                    console.log("***LOG RESPONSE*** **Axios Error** " + JSON.stringify(error));
                                }
                            })

                            this.submitCloseForm()

                            setSubmitting(false);
                        }, 400);
                        
                    }}
                    render={({ errors, status, touched }) => (
                        <Form id="regform">
                            <div className="form-group">
                                
                                <div class="flex flex-col mb-4">
                                    <label class="capitalize font-semibold text-sm text-left text-gray-600" for="firstName">First Name</label>
                                    <Field name="firstName" type="text" class={'modal-form-control' + (errors.firstName && touched.firstName ? ' modal-form-control-error' : '')} />
                                    <ErrorMessage name="firstName" component="div" class="text-xs text-red-500 mt-1" />
                                </div>
                                <div className="flex flex-col mb-4">
                                    <label class="capitalize font-semibold text-sm text-left text-gray-600" for="lastName">Last Name</label>
                                    {/* <Field name="lastName" type="text" className={'form-control' + (errors.lastName && touched.lastName ? ' is-invalid' : '')} /> */}
                                    <Field name="lastName" type="text" class={'modal-form-control' + (errors.lastName && touched.lastName ? ' modal-form-control-error' : '')} />
                                    {/* <ErrorMessage name="lastName" component="div" className="invalid-feedback" /> */}
                                    <ErrorMessage name="lastName" component="div" class="text-xs text-red-500 mt-1" />
                                </div>
                                <div className="flex flex-col mb-4">
                                    <label class="capitalize font-semibold text-sm text-left text-gray-600" for="eMailPrimary">Email</label>
                                    <Field name="eMailPrimary" type="text" class={'modal-form-control' + (errors.eMailPrimary && touched.eMailPrimary ? ' modal-form-control-error' : '')} />
                                    <ErrorMessage name="eMailPrimary" component="div" class="text-xs text-red-500 mt-1" />
                                </div>
                                <div className="flex flex-col mb-4">
                                    <div class=" font-semibold text-sm text-left text-gray-600" id="usertype">What is your role?</div>
                                    <div role="group" id="usertype-group" aria-labelledby="usertype-group" class="text-left flex flex-col pl-2">
                                        <label for="usertype" class="font-semibold text-sm text-left text-gray-600 pr-4 py-4">
                                        <Field type="radio" class="option-input radio" name="usertype" value="seller"/>
                                            I'm in sales
                                        </label>
                                        <label for="usertype" class="font-semibold text-sm text-left text-gray-600">
                                        <Field type="radio" class="option-input radio" name="usertype" value="customer"/>
                                            I'm a customer
                                        </label>
                                    </div>
                                    <ErrorMessage name="usertype" component="div" class="text-xs text-red-500 mt-1" />
                                </div>
                                {/* Submit Button */}
                                <div class="pt-10">
                                    <button id="btn-aboutregister-id" type="submit" class="submit-btn">Sign Up</button>
                                </div>
                                
                            </div> 
                            
                            

                        </Form>
                        
                    )}
                />
            </div> 

        )
    }
}
