import React from 'react'
import ReactMarkdown from 'react-markdown'
import Image from "gatsby-image"

const AboutScroll = ({key,scheading,scheadingmobile,valheading,valheadingmobile,valbody,image, altText}) => {
    return (
        <>
           <div class="max-w-screen-lg mx-auto my-0 relative pt-10 scmd:pt-36">
                <div class="flex flex-col scmd:flex-row w-full content-center">
                    <div class="w-full scmd:w-4/12 my-auto text-center scmd:text-left">
                        <h5 class="hidden scmd:block">{scheading}</h5>
                        <h5 class="px-8 scmd:hidden">{scheadingmobile}</h5>
                    </div>
                    <div class="w-full scmd:w-4/12 mx-auto text-center px-10 z-20">
                        <div class="inline-block w-7/12 scmd:w-full">
                            <Image className="about-img shadow-2xl" fadeIn={false} loading="eager" fluid={image.childImageSharp.fluid} alt={altText}/>
                        </div>
                    </div>
                    <div class="w-full scmd:w-4/12 text-center scmd:text-right my-auto">
                        <h4 class="hidden scmd:block">{valheading}</h4>
                        <div class="px-8 scmd:hidden">
                            <ReactMarkdown style={{ text: { fontSize: 20 } }}source={valheadingmobile} />
                        </div>
                        <div class="hidden scmd:flex w-full ">
                            <div class="w-2/12">
                                {/* Empty div placeholder to control width */}
                            </div>
                            <div class="w-10/2">
                                <div class="mb-9 text-base">
                                    <ReactMarkdown source={valbody} className="todolist"/>
                                </div>
                            </div>
                        </div>
                        <div class="flex scmd:hidden text-left w-full px-8">

                            <div class="mb-9 text-base">
                                <ReactMarkdown source={valbody} className="todolist"/>
                            </div>

                        </div>
                        
                    </div>
                    
                </div>
            </div> 
        </>
    )
}

export default AboutScroll