import React from 'react'
import Image from "gatsby-image"
import ReactMarkdown from "react-markdown"

const AboutHero = ({hero}) => {
    const{heading,body,image, altText} = hero[0]

    return (
        <div class="max-w-7xl mx-auto my-0 pt-20 sclg:pt-32 relative py-0 px-6 scmd:px-1">
            {/* parent container */}
            <div class="flex flex-wrap flex-start">
                {/* left hero column */}
                <div class="w-full pt-10 scmd:w-7/12 mb-4 flex-grow-0 flex-shrink-0 scmd:pl-5 text-gray-600">
                    {/* heading section */}
                    <h1 class="mb-6">{heading}</h1>
                    {/* body section */}
                    <div class="hero">
                        <ReactMarkdown source={body} />
                    </div>
                </div>
                
                {/* right hero column */}
                    <div class="w-full h-auto scmd:w-5/12 flex-grow-0 scmd:px-5 flex-shrink-0">
                        {/* image section */}
                        <Image className="animate-pulse scmd:animate-none" fadeIn={false} loading="eager" fluid={image.childImageSharp.fluid} alt={altText}/>
                    </div>
            </div>
        </div>
    )
}

export default AboutHero